import {
  FACEBOOK_ADS_MANAGER_CREATIVE_LINK,
  FACEBOOK_ADS_MANAGER_LINK,
  TIKTOK_ADS_MANAGER_CREATIVE_LINK,
} from "~/shared/constants";
import { copyTextToClipboard, getRandomInt } from "~/shared/utils";
import {
  AdGroupAdStatus,
  AdInfo,
  AdInfoResponse,
  AssetInfo,
  Creative,
  CreativeInfo,
  CreativeInfoResponse,
  CreativeResponse,
} from "~/types/Creative";
import { InspirationBoardAsset } from "~/types/InspirationBoard";
import { AdFormat, Provider } from "~/types/shared";

export const useCreatives = () => {
  const getMappedAdInfo = (infos: Array<AdInfoResponse>): Array<AdInfo> => {
    return infos.map<AdInfo>((i) => {
      return {
        ...i,
      };
    });
  };

  const getMappedCreativeInfo = (
    infos: Array<CreativeInfoResponse>,
  ): Array<CreativeInfo> => {
    return infos.map<CreativeInfo>((i) => {
      const images = Array.isArray(i.images) ? i.images : [];
      const videos = Array.isArray(i.videos) ? i.videos : [];
      const video =
        Array.isArray(videos) && videos.length > 0 ? videos[0] : null;
      const image =
        Array.isArray(images) && images.length > 0 ? images[0] : null;

      return {
        ...i,
        images,
        videos,
        video,
        image,
      };
    });
  };

  const getMappedCreatives = (
    creatives: Array<CreativeResponse>,
  ): Array<Creative> => {
    return creatives.map((creative) => {
      const images = Array.isArray(creative.info.images)
        ? creative.info.images
        : [];
      const videos = Array.isArray(creative.info.videos)
        ? creative.info.videos
        : [];
      const video =
        Array.isArray(videos) && videos.length > 0 ? videos[0] : null;
      const image =
        Array.isArray(images) && images.length > 0 ? images[0] : null;

      return {
        info: {
          ...creative.info,
          images,
          videos,
          video,
          image,
        },
        chart: creative.chart,
      };
    });
  };

  const copyPostId = (creative: CreativeInfo) => {
    const { notify } = useNotifications();
    if (creative.postId == null) {
      notify("Post ID not found", "");
      return;
    }
    copyTextToClipboard(creative.postId);
    notify(null, "Post ID copied");
  };

  const copyAdId = (creative: CreativeInfo) => {
    const { notify } = useNotifications();
    if (creative.providerId == null) {
      notify("Ad ID not found", "");
      return;
    }
    copyTextToClipboard(creative.providerId);
    notify(null, "Ad ID copied");
  };

  const getAdsManagerUrl = (input: {
    provider: Provider;
    accountId: string;
    adId: string;
    adsetId: string;
    youtubeUrl?: string;
  }) => {
    switch (input.provider) {
      case Provider.TIKTOK:
        return TIKTOK_ADS_MANAGER_CREATIVE_LINK.replace(
          "$actid",
          input.accountId.toString(),
        ).replace("$adid", input.adId.toString());
      case Provider.META:
        return FACEBOOK_ADS_MANAGER_CREATIVE_LINK.replace(
          "$actid",
          input.accountId.toString(),
        )
          .replace("$adid", input.adId.toString())
          .replace("$adsetid", input.adsetId.toString());
      case Provider.GOOGLE:
        return input.youtubeUrl ?? null;
      default:
        return null;
    }
  };

  const getAdsManagerUrlFromCreatives = (creatives: CreativeInfo[]) => {
    if (creatives.length === 0) return;
    const { getAdAccountById } = useAdAccountStore();
    const adAccountMaybe = getAdAccountById(creatives[0].adAccountId);
    if (!adAccountMaybe) return;
    const adIds = creatives.map((c) => c.providerId).join(",");
    const adSetIds = creatives
      .map(
        (c) =>
          adAccountMaybe.adGroups.find((g) => g.id === c.adGroupId)?.providerId,
      )
      .filter((id): id is string => id != null)
      .join(",");
    const adCampaignIds = creatives
      .map(
        (c) =>
          adAccountMaybe.campaigns.find((g) => g.id === c.adCampaignId)
            ?.providerId,
      )
      .filter((id): id is string => id != null)
      .join(",");
    return FACEBOOK_ADS_MANAGER_LINK.replace(
      "$actid",
      adAccountMaybe.providerId,
    )
      .replace("$adid", adIds)
      .replace("$adsetid", adSetIds)
      .replace("$adcampaignid", adCampaignIds);
  };

  const getAdsManagerUrlFromCreative = (creative: CreativeInfo) => {
    const { getAdAccountById } = useAdAccountStore();
    const adAccountMaybe = getAdAccountById(creative.adAccountId);
    if (!adAccountMaybe) return;
    const adGroupMaybe = adAccountMaybe.adGroups.find(
      (c) => c.id === creative.adGroupId,
    );
    if (!adGroupMaybe) return;
    const url = getAdsManagerUrl({
      provider: adAccountMaybe.provider,
      accountId: adAccountMaybe.providerId,
      adId: creative.providerId,
      adsetId: adGroupMaybe.providerId,
      youtubeUrl:
        Array.isArray(creative.videos) && creative.videos.length > 0
          ? creative.videos[0]
          : undefined,
    });
    return url;
  };

  const navigateToAdsManager = (creative: Creative) => {
    if (!window) return;
    const { getAdAccountById } = useAdAccountStore();
    const adAccountMaybe = getAdAccountById(creative.info.adAccountId);
    if (!adAccountMaybe) return;
    const adGroupMaybe = adAccountMaybe.adGroups.find(
      (c) => c.id === creative.info.adGroupId,
    );
    if (!adGroupMaybe) return;
    const url = getAdsManagerUrl({
      provider: adAccountMaybe.provider,
      accountId: adAccountMaybe.providerId,
      adId: creative.info.providerId,
      adsetId: adGroupMaybe.providerId,
    });
    if (!url) return;
    window.open(url, "_blank");
  };

  const emptyCreativeProviderId = "##empty";

  const getEmptyCreative = (input: { idx: number }): Creative => {
    return {
      info: {
        id: 0 - getRandomInt(1, 10000), // Negative ID to avoid collision with real IDs
        providerId: emptyCreativeProviderId + input.idx,
        providerName: "",
        deleted: false,
        adGroupAdStatus: AdGroupAdStatus.ACTIVE,
        images: [],
        videos: [],
        image: null,
        video: null,
        cta: null,
        landingPageUrl: null,
        headline: null,
        description: null,
        postId: null,
        creativeId: null,
        createDate: null,
        createdAt: "",
        updatedAt: "",
        connectionId: -1,
        adAccountId: -1,
        adCampaignId: -1,
        adGroupId: -1,
        provider: Provider.META,
        primaryMetric: {
          name: "",
          curValue: 0,
          compareValue: 0,
          relPosition: 0,
          absPosition: 0,
        },
        metrics: [],
        adFormat: AdFormat.IMAGE,
        isLabeled: false,
        tags: [],
        imageIds: [],
        videoIds: [],
        imageIdToUrls: {},
        videoIdToUrls: {},
      },
    };
  };

  const allCreativesNotEmpty = (creatives: Array<Creative>): boolean => {
    return creatives.every((c) => !isEmptyCreative(c));
  };

  const isEmptyCreative = (creative: Creative): boolean => {
    return creative.info.providerId.startsWith(emptyCreativeProviderId);
  };

  const toInspirationBoardAsset = (
    creativeInfo: CreativeInfo,
  ): InspirationBoardAsset => {
    const { getProviderLogo } = useConnection();
    const { mediaUrl, images, videos } = getMedia(creativeInfo);
    const { getDisplayCta } = useInspirationBoard();
    return {
      id: 0 - getRandomInt(1, 10000), // Negative ID to avoid collision with real IDs
      uuid: creativeInfo.providerId,
      logo: getProviderLogo(creativeInfo.provider),
      brandName: creativeInfo.providerName,
      libraryId: creativeInfo.providerId,
      creationDate: creativeInfo.createdAt,
      platforms: [],
      description: creativeInfo.description ?? "",
      mediaUrl,
      landingPage: creativeInfo.landingPageUrl ?? "",
      headline: creativeInfo.headline ?? "",
      subHeadline: "",
      cta: creativeInfo.cta ?? "",
      adFormat: creativeInfo.adFormat ?? "",
      boardId: 0 - getRandomInt(1, 10000), // Negative ID to avoid collision with real IDs
      createdAt: creativeInfo.createdAt,
      createdBy: "",
      provider: creativeInfo.provider as any,
      images,
      videos,
      displayCta: getDisplayCta(creativeInfo.cta ?? ""),
      customUpload: false,
      pageId: "",
      adDeliveryStartTime: creativeInfo.createDate ?? "",
      adDeliveryStopTime: "",
      euTotalReach: "",
      country: "",
      targetAges: "",
      targetGender: "",
      targetLocations: "",
      isRunning: creativeInfo.adGroupAdStatus === AdGroupAdStatus.ACTIVE,
      workspaceId: 0 - getRandomInt(1, 10000), // Negative ID to avoid collision with real IDs
    };
  };

  const getVideos = (creativeInfo: CreativeInfo) => {
    const videos = creativeInfo
      ? creativeInfo.videos.filter((c) => c.startsWith("http"))
      : [];

    return Array.from(new Set(videos));
  };

  const getImages = (creativeInfo: CreativeInfo) => {
    const images = creativeInfo
      ? creativeInfo.images.filter((c) => c.startsWith("http"))
      : [];

    return Array.from(new Set(images));
  };

  const getMedia = (
    creativeInfo: CreativeInfo,
  ): {
    mediaUrl: string;
    images?: Array<string>;
    videos?: Array<string>;
  } => {
    if (creativeInfo == null) {
      return {
        mediaUrl: "",
      };
    }

    const videos = getVideos(creativeInfo);
    const images = getImages(creativeInfo);

    if (videos.length > 0) {
      return {
        mediaUrl: videos[0],
        videos,
        images,
      };
    } else if (images.length > 0) {
      return {
        mediaUrl: images[0],
        images,
        videos,
      };
    } else {
      return {
        mediaUrl: "",
      };
    }
  };

  const copyCreativetShareLink = (providerId: string) => {
    const config = useRuntimeConfig();
    const { notify } = useNotifications();
    const shareLink =
      config.public.baseUrl + "/creative-library/share/" + providerId;
    copyTextToClipboard(shareLink);
    notify(null, "Link copied");
  };

  const adInfoToCreative = (
    adInfo: AdInfo,
    assetInfos: AssetInfo[],
  ): Creative => {
    const imageIdToUrls = assetInfos
      .filter((a) => a.providerId === adInfo.providerId)
      .map((a) => a.imageIdToUrls)
      .flat()
      .reduce((acc, val) => ({ ...acc, ...val }), {});
    const videoIdToUrls = assetInfos
      .filter((a) => a.providerId === adInfo.providerId)
      .map((a) => a.videoIdToUrls)
      .flat()
      .reduce((acc, val) => ({ ...acc, ...val }), {});
    const images = assetInfos
      .filter((a) => a.providerId === adInfo.providerId)
      .map((a) => a.images)
      .flat();
    const videos = assetInfos
      .filter((a) => a.providerId === adInfo.providerId)
      .map((a) => a.videos)
      .flat();
    const video = Array.isArray(videos) && videos.length > 0 ? videos[0] : null;
    const image = Array.isArray(images) && images.length > 0 ? images[0] : null;
    return {
      info: {
        ...adInfo,
        images,
        videos,
        image,
        video,
        imageIdToUrls,
        videoIdToUrls,
        primaryMetric: {
          name: "",
          curValue: 0,
          compareValue: 0,
          relPosition: 0,
          absPosition: 0,
        },
        metrics: [],
      },
    };
  };

  return {
    getMappedAdInfo,
    getMappedCreativeInfo,
    getMappedCreatives,
    copyPostId,
    copyAdId,
    getAdsManagerUrl,
    navigateToAdsManager,
    getEmptyCreative,
    emptyCreativeProviderId,
    allCreativesNotEmpty,
    isEmptyCreative,
    toInspirationBoardAsset,
    getAdsManagerUrlFromCreative,
    getAdsManagerUrlFromCreatives,
    copyCreativetShareLink,
    adInfoToCreative,
  };
};
