import { defineStore } from "pinia";
import { getBatchStartEnd, sortAscending } from "~/shared/utils";
import { ClientLayout, ClientTargetMetric } from "~/types/Client";
import { Creative, CreativeResponse } from "~/types/Creative";
import { MetricsFilter, CustomMetricRule } from "~/types/Metrics";
import { ReportSnapshotResponse } from "~/types/ReportSnapshot";
import { AdGroupAdTag } from "~/types/Tagging";
import {
  GetTestingLogOverrides,
  TestingLogColumn,
  TestingLogColumnGroup,
  TestingLogColumnInfo,
  TestingLogColumnInfoResponse,
  TestingLogColumnResponse,
  TestingLogReport,
  TestingLogReportResponse,
  TestingLogView,
  TestingLogViewResponse,
} from "~/types/TestingLog";
import {
  AttributionWindow,
  Provider,
  Sort,
  SortBy,
  Timeframe,
  ViewType,
} from "~/types/shared";

export const useTestingLogStore = defineStore({
  id: "testing-log-store",
  state: () => {
    return {
      reports: [],
      columns: [],
      customConversionNames: {},
      customMetricRules: [],
      colorCodingLayout: ClientLayout.RELATIVE,
      targetMetrics: [],
      currency: "EUR",
      columnDefinitions: [],
    } as {
      reports: TestingLogReport[];
      columns: TestingLogColumn[];
      customConversionNames: Record<string, string>;
      customMetricRules: CustomMetricRule[];
      colorCodingLayout: ClientLayout;
      targetMetrics: ClientTargetMetric[];
      currency: string;
      columnDefinitions: TestingLogColumnInfo[];
    };
  },
  actions: {
    clear() {
      this.reports = [];
      this.columns = [];
      this.customConversionNames = {};
      this.customMetricRules = [];
      this.colorCodingLayout = ClientLayout.RELATIVE;
      this.targetMetrics = [];
      this.currency = "EUR";
      this.columnDefinitions = [];
    },

    async createReport(input: {
      title: string | null;
      clientId: number;
      folderId: number | null;
      description: string | undefined | null;
      meta: boolean;
      tiktok: boolean;
      youtube: boolean;
    }) {
      const { data, error } = await useDatAdsApiFetch<{
        data: { report: TestingLogReportResponse };
      }>("/testing-log/create-report", {
        method: "POST",
        body: {
          title: input.title === "" ? null : input.title,
          description: input.description === "" ? null : input.description,
          clientId: input.clientId,
          meta: input.meta,
          tiktok: input.tiktok,
          folderId: input.folderId,
          youtube: input.youtube,
        },
      });
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      if (data.value) {
        const { getMappedTestingLogReports } = useTestingLog();
        const report = getMappedTestingLogReports([data.value.data.report])[0];
        this.reports.push(report);
        return { id: report.id, uuid: report.uuid };
      }
      return null;
    },

    async duplicateReport(input: {
      reportId: number;
      clientId: number;
      folderId: number | null;
    }) {
      const { data, error } = await useDatAdsApiFetch<{
        data: { report: TestingLogReportResponse };
      }>(`testing-log/duplicate-report`, {
        method: "POST",
        body: {
          clientId: input.clientId,
          reportId: input.reportId,
          folderId: input.folderId,
        },
      });
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      if (data.value) {
        const { getMappedTestingLogReports } = useTestingLog();
        const report = getMappedTestingLogReports([data.value.data.report])[0];
        this.reports.push(report);
        return { id: report.id, uuid: report.uuid };
      }
      return null;
    },

    async createSnapshot(dto: { reportId: number }) {
      const { data, error } = await useDatAdsApiFetch<{
        data: {
          snapshot: ReportSnapshotResponse<TestingLogReportResponse>;
        };
      }>("/testing-log/snapshot", {
        method: "POST",
        body: { reportId: dto.reportId },
      });
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      if (data.value) {
        const { getMappedTestingLogReportSnapshpts } = useTestingLog();
        const snapshot = getMappedTestingLogReportSnapshpts([
          data.value.data.snapshot,
        ])[0];
        return snapshot;
      }
      return null;
    },

    async updateReportMetadata(input: {
      reportId: number;
      title?: string | undefined | null;
      description?: string | undefined | null;
      selectedViewType?: ViewType | undefined | null;
    }) {
      const { error } = await useDatAdsApiFetch(
        `testing-log/report-metadata/${input.reportId}`,
        {
          method: "PATCH",
          body: {
            title: input.title === "" ? null : input.title,
            description: input.description === "" ? null : input.description,
            selectedViewType: input.selectedViewType,
          },
        },
      );
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      const reportIndex = this.reports.findIndex(
        (report) => report.id === input.reportId,
      );
      if (reportIndex !== -1) {
        this.reports.splice(reportIndex, 1, {
          ...this.reports[reportIndex],
          ...input,
        });
      }
    },

    async updateReport(input: {
      reportId: number;
      timeframe?: Timeframe | undefined | null;
      startDate?: string | undefined | null;
      endDate?: string | undefined | null;
      returnProvider: Provider;
    }) {
      const { data, error } = await useDatAdsApiFetch<{
        data: {
          report: TestingLogReportResponse;
          customConversionNames: Record<string, string>;
          customMetricRules: CustomMetricRule[];
        };
      }>(`testing-log/report/${input.reportId}`, {
        method: "PATCH",
        body: {
          timeframe: input.timeframe ?? undefined,
          startDate: input.startDate ?? undefined,
          endDate: input.endDate ?? undefined,
          returnProvider: input.returnProvider,
        },
      });
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      if (data.value) {
        this.customConversionNames = data.value.data.customConversionNames;
        this.customMetricRules = data.value.data.customMetricRules;
        const { getMappedTestingLogReports } = useTestingLog();
        const report = getMappedTestingLogReports([data.value.data.report])[0];
        this.setReport(report);
        return report;
      }
      return null;
    },

    async updateView(input: {
      viewId: number;
      filter?: Array<Array<MetricsFilter>> | null;
    }) {
      const { data, error } = await useDatAdsApiFetch<{
        data: { view: TestingLogViewResponse };
      }>(`testing-log/view/${input.viewId}`, {
        method: "PATCH",
        body: {
          filter: input.filter,
        },
      });
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      if (data.value) {
        const { getMappedTestingLogViews } = useTestingLog();
        const view = getMappedTestingLogViews([data.value.data.view])[0];
        const reportIdx = this.reports.findIndex(
          (report) => report.id === view.info.reportId,
        );
        this.setView(view, reportIdx);
        return view;
      }
      return null;
    },

    async setupView(viewId: number) {
      const { data, error } = await useDatAdsApiFetch<{
        data: { view: TestingLogViewResponse };
      }>(`testing-log/setup-view/${viewId}`, {
        method: "POST",
      });
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      if (data.value) {
        const { getMappedTestingLogViews } = useTestingLog();
        const view = getMappedTestingLogViews([data.value.data.view])[0];
        const reportIdx = this.reports.findIndex(
          (report) => report.id === view.info.reportId,
        );
        this.setView(view, reportIdx);
        return view;
      }
      return null;
    },

    async getReport(
      input: {
        reportUuid: string;
        provider?: Provider;
        resolveAssets?: boolean;
      } & GetTestingLogOverrides,
    ) {
      const { getOverridesQuery } = useQuery();
      const { data, error } = await useDatAdsApiFetch<{
        data: {
          report: TestingLogReportResponse;
          customConversionNames: Record<string, string>;
          customMetricRules: CustomMetricRule[];
          colorCodingLayout: ClientLayout;
          targetMetrics: ClientTargetMetric[];
          currency: string;
        };
      }>(`testing-log/report/${input.reportUuid}`, {
        query: {
          ...getOverridesQuery(input),
          provider: input.provider,
          resolveAssets: input.resolveAssets,
        },
      });
      if (error.value) {
        const errorMesage = useErrorHandler(error.value);
        return errorMesage;
      }
      if (data.value) {
        this.customConversionNames = data.value.data.customConversionNames;
        this.colorCodingLayout = data.value.data.colorCodingLayout;
        this.targetMetrics = data.value.data.targetMetrics;
        this.customMetricRules = data.value.data.customMetricRules;
        this.currency = data.value.data.currency;
        const { getMappedTestingLogReports } = useTestingLog();
        const report = getMappedTestingLogReports([data.value.data.report])[0];
        this.setReport(report);
        return report;
      }
      return null;
    },

    async getReportSnapshot(dto: { reportUuid: string }) {
      const { data, error } = await useDatAdsApiFetch<{
        data: {
          snapshot: ReportSnapshotResponse<TestingLogReportResponse>;
          customConversionNames: Record<string, string>;
          colorCodingLayout: ClientLayout;
          targetMetrics: ClientTargetMetric[];
          customMetricRules: CustomMetricRule[];
          currency: string;
        };
      }>(`testing-log/report/${dto.reportUuid}/snapshot`);
      if (error.value) {
        const errorMesage = useErrorHandler(error.value);
        return errorMesage;
      }
      if (data.value) {
        this.customConversionNames = data.value.data.customConversionNames;
        this.colorCodingLayout = data.value.data.colorCodingLayout;
        this.targetMetrics = data.value.data.targetMetrics;
        this.customMetricRules = data.value.data.customMetricRules;
        this.currency = data.value.data.currency;
        const { getMappedTestingLogReportSnapshpts } = useTestingLog();
        const snapshot = getMappedTestingLogReportSnapshpts([
          data.value.data.snapshot,
        ])[0];
        this.setReport(snapshot.data);
        return snapshot;
      }
      return null;
    },

    async getView(
      input: {
        viewUuid: string;
        resolveAssets?: boolean;
      } & GetTestingLogOverrides,
    ) {
      const { getOverridesQuery } = useQuery();
      const { data, error } = await useDatAdsApiFetch<{
        data: {
          view: TestingLogViewResponse;
          customConversionNames: Record<string, string>;
          colorCodingLayout: ClientLayout;
          targetMetrics: ClientTargetMetric[];
          customMetricRules: CustomMetricRule[];
          currency: string;
        };
      }>(`testing-log/view/${input.viewUuid}`, {
        query: {
          ...getOverridesQuery(input),
          resolveAssets: input.resolveAssets,
        },
      });
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      if (data.value) {
        this.customConversionNames = data.value.data.customConversionNames;
        this.colorCodingLayout = data.value.data.colorCodingLayout;
        this.targetMetrics = data.value.data.targetMetrics;
        this.customMetricRules = data.value.data.customMetricRules;
        this.currency = data.value.data.currency;
        const { getMappedTestingLogViews } = useTestingLog();
        const view = getMappedTestingLogViews([data.value.data.view])[0];
        const reportIdx = this.reports.findIndex((report) =>
          report.views.some((v) => v.info.uuid === input.viewUuid),
        );
        this.setView(view, reportIdx);
      }
      return null;
    },

    async getColumnDefinitions(dto: {
      clientId: number;
      provider: Provider;
      viewId?: number;
    }) {
      const { data, error } = await useDatAdsApiFetch<{
        data: { columns: TestingLogColumnInfoResponse[] };
      }>(`/testing-log/column-definitions`, {
        method: "POST",
        body: dto,
      });
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      if (data.value) {
        this.columnDefinitions = data.value.data.columns.sort((a, b) =>
          sortAscending(a.order, b.order),
        );
        return this.columnDefinitions;
      }
      return null;
    },

    async listReports(clientId: number) {
      const { data, error } = await useDatAdsApiFetch<{
        data: { reports: TestingLogReportResponse[] };
      }>(`testing-log/report/client/${clientId}`);
      if (error.value) {
        useErrorHandler(error.value);
        return [];
      }
      if (data.value) {
        const { getMappedTestingLogReports } = useTestingLog();
        const reports = getMappedTestingLogReports(data.value.data.reports);
        this.setReports(reports);
      }
      return this.reports.filter((report) => report.clientId === clientId);
    },

    async deleteReport(reportId: number) {
      const { error } = await useDatAdsApiFetch(
        `testing-log/delete-report/${reportId}`,
        {
          method: "DELETE",
        },
      );
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      const index = this.reports.findIndex((report) => report.id === reportId);
      if (index !== -1) {
        this.reports.splice(index, 1);
      }
      return null;
    },

    setReports(reports: TestingLogReport[]) {
      // Remove all reports not in returned list
      this.reports = this.reports.filter((report) =>
        reports.some((r) => r.id === report.id),
      );
      // Add or update reports
      reports.forEach((r) => this.setReport(r));
    },

    async createColumn(
      dto: {
        title: string;
        provider: Provider;
        description: string | null;
        clientId: number;
        primary?: boolean;
      },
      reportId: number | null = null,
      viewId: number | null = null,
    ) {
      const appUserMe = useAppUserStore().appUserMe;
      if (appUserMe == null) return null;
      const { data, error } = await useDatAdsApiFetch<{
        data: {
          column: TestingLogColumnResponse;
        };
      }>("/testing-log/create-column", {
        method: "POST",
        body: {
          ...dto,
          reportId: reportId ?? undefined,
          viewId: viewId ?? undefined,
        },
      });
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      if (data.value) {
        const { getMappedTestingLogColumns } = useTestingLog();
        const newColumn = getMappedTestingLogColumns([
          data.value.data.column,
        ])[0];
        if (typeof viewId === "number") {
          const reportIdx = this.reports.findIndex((report) =>
            report.views.some((view) => view.info.id === viewId),
          );
          const viewIdx = this.reports[reportIdx].views.findIndex(
            (view) => view.info.id === viewId,
          );
          this.reports[reportIdx].views[viewIdx].columns.push(newColumn);
        } else {
          this.columns.push(newColumn);
        }
        return newColumn;
      }
      return null;
    },

    async createGroups(
      dto: {
        title: string;
        filter: MetricsFilter[][] | null | undefined;
        columnId: number;
      }[],
    ) {
      if (dto.length === 0) return [];
      const { data, error } = await useDatAdsApiFetch<{
        data: {
          id: number;
          createdAt: string;
          uuid: string;
          order: number;
          title: string;
        }[];
      }>("/testing-log/create-groups", {
        method: "POST",
        body: dto,
      });
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      if (data.value) {
        return data.value.data;
      }
      return null;
    },

    async updateColumnMetadata(
      dto: {
        columnId: number;
        title?: string | undefined | null;
        description?: string | undefined | null;
        selected?: boolean | undefined | null;
      },
      viewId: number | null = null,
    ) {
      const { data, error } = await useDatAdsApiFetch<{
        data: { columns: TestingLogColumnResponse[] };
      }>(`/testing-log/update-column-metadata/${dto.columnId}`, {
        method: "PATCH",
        body: {
          ...dto,
          columnId: undefined,
        },
      });
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      if (data.value && typeof viewId === "number") {
        const reportIdx = this.reports.findIndex((report) =>
          report.views.some((view) => view.info.id === viewId),
        );
        const viewIdx = this.reports[reportIdx].views.findIndex(
          (view) => view.info.id === viewId,
        );
        const columnIndex = this.reports[reportIdx].views[
          viewIdx
        ].columns.findIndex((column) => column.info.id === dto.columnId);
        if (columnIndex !== -1) {
          this.reports[reportIdx].views[viewIdx].columns.splice(
            columnIndex,
            1,
            {
              ...this.reports[reportIdx].views[viewIdx].columns[columnIndex],
              info: {
                ...this.reports[reportIdx].views[viewIdx].columns[columnIndex]
                  .info,
                title:
                  dto.title ??
                  this.reports[reportIdx].views[viewIdx].columns[columnIndex]
                    .info.title,
                description:
                  dto.description ??
                  this.reports[reportIdx].views[viewIdx].columns[columnIndex]
                    .info.description,
              },
            },
          );
        }
      } else if (data.value) {
        const columnIndex = this.columns.findIndex(
          (column) => column.info.id === dto.columnId,
        );
        if (columnIndex !== -1) {
          this.columns.splice(columnIndex, 1, {
            ...this.columns[columnIndex],
            info: {
              ...this.columns[columnIndex].info,
              title: dto.title ?? this.columns[columnIndex].info.title,
              description:
                dto.description ?? this.columns[columnIndex].info.description,
            },
          });
        }
      }
      return null;
    },

    async setColumnAsPrimaryColumn(dto: {
      clientId: number;
      provider: Provider;
      newPrimaryColumnId: number;
      viewId?: number;
    }) {
      const { data, error } = await useDatAdsApiFetch(
        `/testing-log/update-column-primary`,
        {
          method: "PATCH",
          body: dto,
        },
      );
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      if (data.value) {
        if (typeof dto.viewId === "number") {
          this.setViewColumnAsPrimaryColumn({
            columnId: dto.newPrimaryColumnId,
            viewId: dto.viewId,
          });
        } else {
          this.setCockpitColumnAsPrimaryColumn({
            columnId: dto.newPrimaryColumnId,
          });
        }
        return null;
      }
      return null;
    },

    setCockpitColumnAsPrimaryColumn(dto: { columnId: number }) {
      this.columns = this.columns.map((column) => {
        return {
          ...column,
          info: {
            ...column.info,
            primary: column.info.id === dto.columnId,
          },
        };
      });
    },

    setViewColumnAsPrimaryColumn(dto: { columnId: number; viewId: number }) {
      for (const report of this.reports) {
        for (const view of report.views) {
          if (view.info.id === dto.viewId) {
            view.columns = view.columns.map((column) => {
              return {
                ...column,
                info: {
                  ...column.info,
                  primary: column.info.id === dto.columnId,
                },
              };
            });
          }
        }
      }
    },

    async updateColumnSelected(dto: { columnId: number; selected: boolean }[]) {
      if (dto.length === 0) return null;
      const { error } = await useDatAdsApiFetch(
        `/testing-log/update-column-selected`,
        {
          method: "PATCH",
          body: dto,
        },
      );
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      return null;
    },

    async updateColumnOrder(
      dto: { columnId: number; order: number }[],
      viewId: number | null = null,
    ) {
      if (dto.length === 0) return null;
      if (typeof viewId === "number") {
        this.setViewColumnOrder(dto, viewId);
      } else {
        this.setCockpitColumnOrder(dto);
      }
      const { error } = await useDatAdsApiFetch(
        `/testing-log/update-column-order`,
        {
          method: "PATCH",
          body: dto,
        },
      );
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      return null;
    },

    setCockpitColumnOrder(dto: { columnId: number; order: number }[]) {
      for (const { columnId, order } of dto) {
        const columnIndex = this.columns.findIndex(
          (column) => column.info.id === columnId,
        );
        if (columnIndex !== -1) {
          this.columns.splice(columnIndex, 1, {
            ...this.columns[columnIndex],
            info: {
              ...this.columns[columnIndex].info,
              order,
            },
          });
        }
      }
    },

    setViewColumnOrder(
      dto: { columnId: number; order: number }[],
      viewId: number,
    ) {
      const reportIdx = this.reports.findIndex((report) =>
        report.views.some((view) => view.info.id === viewId),
      );
      const viewIdx = this.reports[reportIdx].views.findIndex(
        (view) => view.info.id === viewId,
      );
      for (const { columnId, order } of dto) {
        const columnIndex = this.reports[reportIdx].views[
          viewIdx
        ].columns.findIndex((column) => column.info.id === columnId);
        if (columnIndex !== -1) {
          this.reports[reportIdx].views[viewIdx].columns.splice(
            columnIndex,
            1,
            {
              ...this.reports[reportIdx].views[viewIdx].columns[columnIndex],
              info: {
                ...this.reports[reportIdx].views[viewIdx].columns[columnIndex]
                  .info,
                order,
              },
            },
          );
        }
      }
    },

    async updateColumn(
      dto: {
        columnId: number;
        timeframe?: Timeframe | null;
        startDate?: string | null;
        endDate?: string | null;
        primaryMetric?: string;
        secondaryMetrics?: Array<string>;
        sort?: Sort;
        attributionWindow?: AttributionWindow;
        sortBy?: SortBy;
      },
      viewId: number | null = null,
    ) {
      const { data, error } = await useDatAdsApiFetch<{
        data: { columns: TestingLogColumnResponse[] };
      }>(`/testing-log/update-column/${dto.columnId}`, {
        method: "PATCH",
        body: {
          ...dto,
          columnId: undefined,
        },
      });
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      const { getMappedTestingLogColumns } = useTestingLog();
      if (data.value && typeof viewId === "number") {
        const columns = getMappedTestingLogColumns(data.value.data.columns);
        const reportIdx = this.reports.findIndex((report) =>
          report.views.some((view) => view.info.id === viewId),
        );
        const viewIdx = this.reports[reportIdx].views.findIndex(
          (view) => view.info.id === viewId,
        );
        this.reports[reportIdx].views[viewIdx].columns = [...columns];
        const columnIndex = this.reports[reportIdx].views[
          viewIdx
        ].columns.findIndex((column) => column.info.id === dto.columnId);
        return {
          ...this.reports[reportIdx].views[viewIdx].columns[columnIndex],
        };
      } else if (data.value) {
        const columns = getMappedTestingLogColumns(data.value.data.columns);
        this.columns = [...columns];
        const columnIndex = this.columns.findIndex(
          (column) => column.info.id === dto.columnId,
        );
        return { ...this.columns[columnIndex] };
      }
      return null;
    },

    async updateGroups(
      dto: {
        groupId: number;
        title?: string | undefined | null;
        filter?: MetricsFilter[][] | undefined | null;
        order?: number | undefined | null;
        columnId?: number | undefined | null;
      }[],
    ) {
      if (dto.length === 0) return null;
      const { error } = await useDatAdsApiFetch<{
        data: { column: TestingLogColumnResponse };
      }>(`/testing-log/update-groups`, {
        method: "PATCH",
        body: dto,
      });
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      return null;
    },

    async deleteColumnsOfClient(
      clientId: number,
      viewId: number | null = null,
    ) {
      const { error } = await useDatAdsApiFetch(
        `/testing-log/client/${clientId}/delete-columns`,
        {
          method: "DELETE",
        },
      );
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      if (typeof viewId === "number") {
        const reportIdx = this.reports.findIndex((report) =>
          report.views.some((view) => view.info.id === viewId),
        );
        const viewIdx = this.reports[reportIdx].views.findIndex(
          (view) => view.info.id === viewId,
        );
        this.reports[reportIdx].views[viewIdx].columns = [];
      } else {
        this.columns = [];
      }
      return null;
    },

    async deleteColumn(columnId: number, viewId: number | null = null) {
      const { error } = await useDatAdsApiFetch(
        `/testing-log/delete-column/${columnId}`,
        {
          method: "DELETE",
        },
      );
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      if (typeof viewId === "number") {
        const reportIdx = this.reports.findIndex((report) =>
          report.views.some((view) => view.info.id === viewId),
        );
        const viewIdx = this.reports[reportIdx].views.findIndex(
          (view) => view.info.id === viewId,
        );
        const columnIndex = this.reports[reportIdx].views[
          viewIdx
        ].columns.findIndex((column) => column.info.id === columnId);
        if (columnIndex !== -1) {
          this.reports[reportIdx].views[viewIdx].columns.splice(columnIndex, 1);
        }
      } else {
        this.columns = this.columns.filter(
          (column) => column.info.id !== columnId,
        );
      }
      return null;
    },

    async deleteGroups(groupIds: number[]) {
      if (groupIds.length === 0) return null;
      const { error } = await useDatAdsApiFetch(`/testing-log/delete-groups`, {
        method: "DELETE",
        body: groupIds.map((groupId) => ({
          groupId,
        })),
      });
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      return null;
    },

    async listColumns(
      dto: {
        clientId: number;
        provider: Provider;
        timeframe?: Timeframe | undefined | null;
        startDate?: string | undefined | null;
        endDate?: string | undefined | null;
        primaryMetric?: string | undefined | null;
        secondaryMetrics?: string[] | undefined | null;
        sort?: Sort | undefined | null;
        attributionWindow?: AttributionWindow | undefined | null;
        sortBy?: SortBy | undefined | null;
        resolveAssets?: boolean;
        filter?: Array<Array<MetricsFilter>> | undefined | null;
      },
      viewUuid: string | null = null,
    ) {
      const path =
        viewUuid != null
          ? `/testing-log/view/columns/${viewUuid}`
          : `/testing-log/list-columns/${dto.clientId}`;
      const { data, error } = await useDatAdsApiFetch<{
        data: {
          columns: TestingLogColumnResponse[];
          customConversionNames: Record<string, string>;
          colorCodingLayout: ClientLayout;
          targetMetrics: ClientTargetMetric[];
          customMetricRules: CustomMetricRule[];
          currency: string;
        };
      }>(path, {
        method: "GET",
        query: {
          provider: dto.provider ?? undefined,
          timeframe: dto.timeframe ?? undefined,
          startDate: dto.startDate ?? undefined,
          endDate: dto.endDate ?? undefined,
          primaryMetric: dto.primaryMetric ?? undefined,
          secondaryMetrics: Array.isArray(dto.secondaryMetrics)
            ? JSON.stringify(dto.secondaryMetrics)
            : undefined,
          sort: dto.sort ?? undefined,
          attributionWindow: dto.attributionWindow ?? undefined,
          sortBy: dto.sortBy ?? undefined,
          resolveAssets: dto.resolveAssets ?? undefined,
          filter: dto.filter != null ? JSON.stringify(dto.filter) : undefined,
        },
      });
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      if (data.value) {
        this.customConversionNames = data.value.data.customConversionNames;
        this.colorCodingLayout = data.value.data.colorCodingLayout;
        this.targetMetrics = data.value.data.targetMetrics;
        this.customMetricRules = data.value.data.customMetricRules;
        this.currency = data.value.data.currency;
        const { getMappedTestingLogColumns } = useTestingLog();
        const columns = getMappedTestingLogColumns(data.value.data.columns);
        if (typeof viewUuid === "string") {
          const reportIdx = this.reports.findIndex((report) =>
            report.views.some((view) => view.info.uuid === viewUuid),
          );
          const viewIdx = this.reports[reportIdx].views.findIndex(
            (view) => view.info.uuid === viewUuid,
          );
          this.reports[reportIdx].views[viewIdx].columns = [...columns];
          return this.reports[reportIdx].views[viewIdx].columns;
        } else {
          this.columns = [...columns];
          return this.columns;
        }
      }
      return null;
    },

    async listCreativesOfGroup(
      dto: {
        columnId: number;
        columnUuid: string;
        cellIdx: number;
        groupId: number;
        pageNumber: number;
        pageSize: number;
        timeframe?: Timeframe | undefined | null;
        startDate?: string | undefined | null;
        endDate?: string | undefined | null;
        primaryMetric?: string | undefined | null;
        secondaryMetrics?: string[] | undefined | null;
        sort?: Sort | undefined | null;
        attributionWindow?: AttributionWindow | undefined | null;
        sortBy?: SortBy | undefined | null;
        resolveAssets?: boolean;
      },
      viewId: number | null = null,
    ) {
      const { hasFetchedCreatives, creativesBatch } =
        typeof viewId === "number"
          ? this.getViewFetchedCreatives({ ...dto, viewId })
          : this.getCockpitFetchedCreatives(dto);
      if (hasFetchedCreatives) {
        return creativesBatch;
      }
      const path =
        viewId != null
          ? `/testing-log/column/${dto.columnUuid}/group/${dto.groupId}/creatives`
          : `/testing-log/get-column/${dto.columnId}/list-creatives`;
      const { data, error } = await useDatAdsApiFetch<{
        data: { creatives: CreativeResponse[] };
      }>(path, {
        method: "GET",
        query: {
          timeframe: dto.timeframe ?? undefined,
          startDate: dto.startDate ?? undefined,
          endDate: dto.endDate ?? undefined,
          primaryMetric: dto.primaryMetric ?? undefined,
          secondaryMetrics: Array.isArray(dto.secondaryMetrics)
            ? JSON.stringify(dto.secondaryMetrics)
            : undefined,
          sort: dto.sort ?? undefined,
          pageNumber: dto.pageNumber,
          pageSize: dto.pageSize,
          groupId: viewId == null ? dto.groupId : undefined,
          cellIdx: dto.cellIdx,
          attributionWindow: dto.attributionWindow ?? undefined,
          sortBy: dto.sortBy ?? undefined,
          resolveAssets: dto.resolveAssets,
        },
      });
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      if (data.value) {
        const { getMappedCreatives } = useCreatives();
        const creatives = getMappedCreatives(data.value.data.creatives);
        if (typeof viewId === "number") {
          this.setViewCreatives({
            ...dto,
            creatives,
            viewId,
          });
        } else {
          this.setCockpitCreatives({
            ...dto,
            creatives,
          });
        }
      }
      return null;
    },

    async getAdNameTemplate(dto: { clientId: number; provider: Provider }) {
      const { data, error } = await useDatAdsApiFetch<{
        data: { adNameTemplate: Record<string, string[]> };
      }>(`/testing-log/ad-name-template`, {
        query: dto,
      });
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      if (data.value) {
        return data.value.data.adNameTemplate;
      }
      return null;
    },

    async resolveGroupAssets(dto: { groups: Array<TestingLogColumnGroup> }) {
      const { resolveAdGroupAdAssets } = useAssets();
      const ads = dto.groups.flatMap((g) => g.creatives).map((c) => c.info);
      const adsWithAssets = await resolveAdGroupAdAssets(ads);
      const { getMappedCreatives } = useCreatives();
      const { getMappedTestingLogGroups } = useTestingLog();
      for (const column of this.columns) {
        for (const cell of column.cells) {
          cell.groups = getMappedTestingLogGroups(
            cell.groups.map((g) => {
              return {
                ...g,
                creatives: getMappedCreatives(
                  g.creatives.map((c) => {
                    const info = c.info;
                    const images = adsWithAssets.find(
                      (a) => a.id === info.id,
                    )?.images;
                    const videos = adsWithAssets.find(
                      (a) => a.id === info.id,
                    )?.videos;
                    return {
                      ...c,
                      info: {
                        ...info,
                        images: images ?? info.images,
                        videos: videos ?? info.videos,
                      },
                    };
                  }),
                ),
              };
            }),
          );
        }
      }
      for (const report of this.reports) {
        for (const view of report.views) {
          for (const column of view.columns) {
            for (const cell of column.cells) {
              cell.groups = getMappedTestingLogGroups(
                cell.groups.map((g) => {
                  return {
                    ...g,
                    creatives: getMappedCreatives(
                      g.creatives.map((c) => {
                        const info = c.info;
                        const images = adsWithAssets.find(
                          (a) => a.id === info.id,
                        )?.images;
                        const videos = adsWithAssets.find(
                          (a) => a.id === info.id,
                        )?.videos;
                        return {
                          ...c,
                          info: {
                            ...info,
                            images: images ?? info.images,
                            videos: videos ?? info.videos,
                          },
                        };
                      }),
                    ),
                  };
                }),
              );
            }
          }
        }
      }
    },

    setReport(report: TestingLogReport) {
      const index = this.reports.findIndex((r) => r.id === report.id);
      if (index === -1) {
        this.reports.push(report);
      } else {
        report.views.forEach((view) => this.setView(view, index));
        // Keep views
        this.reports.splice(index, 1, {
          ...report,
          views: this.reports[index].views,
        });
      }
    },

    setView(view: TestingLogView, reportIdx: number) {
      const views = this.reports[reportIdx].views;
      const index = views.findIndex((v) => v.info.id === view.info.id);
      if (index === -1) {
        views.push(view);
      } else {
        views.splice(index, 1, view);
      }
    },

    setCockpitCreatives(dto: {
      creatives: Creative[];
      columnId: number;
      cellIdx: number;
      groupId: number;
      pageNumber: number;
      pageSize: number;
    }) {
      const columnIdx = this.columns.findIndex(
        (column) => column.info.id === dto.columnId,
      );
      const groupIdx = this.columns[columnIdx].cells[
        dto.cellIdx
      ].groups.findIndex((group) => group.info.id === dto.groupId);
      dto.creatives.forEach((creative, batchIdx) => {
        this.setCreative({
          creative,
          column: this.columns[columnIdx],
          cellIdx: dto.cellIdx,
          groupIdx,
          pageNumber: dto.pageNumber,
          pageSize: dto.pageSize,
          batchIdx,
        });
      });
    },

    setViewCreatives(dto: {
      creatives: Creative[];
      viewId: number;
      columnId: number;
      cellIdx: number;
      groupId: number;
      pageNumber: number;
      pageSize: number;
    }) {
      const reportIdx = this.reports.findIndex((report) =>
        report.views.some((view) => view.info.id === dto.viewId),
      );
      const viewIdx = this.reports[reportIdx].views.findIndex(
        (view) => view.info.id === dto.viewId,
      );
      const columnIdx = this.reports[reportIdx].views[
        viewIdx
      ].columns.findIndex((column) => column.info.id === dto.columnId);
      const groupIdx = this.reports[reportIdx].views[viewIdx].columns[
        columnIdx
      ].cells[dto.cellIdx].groups.findIndex(
        (group) => group.info.id === dto.groupId,
      );
      dto.creatives.forEach((creative, batchIdx) => {
        this.setCreative({
          creative,
          column: this.reports[reportIdx].views[viewIdx].columns[columnIdx],
          cellIdx: dto.cellIdx,
          groupIdx,
          pageNumber: dto.pageNumber,
          pageSize: dto.pageSize,
          batchIdx,
        });
      });
    },

    setCreative(input: {
      creative: Creative;
      column: TestingLogColumn;
      cellIdx: number;
      groupIdx: number;
      pageNumber: number;
      pageSize: number;
      batchIdx: number; // Index of creative in batch
    }) {
      const { getEmptyCreative, isEmptyCreative } = useCreatives();

      const creatives =
        input.column.cells[input.cellIdx].groups[input.groupIdx].creatives;
      const total =
        input.column.cells[input.cellIdx].groups[input.groupIdx].total;
      const { start, end } = getBatchStartEnd({
        pageNumber: input.pageNumber,
        pageSize: input.pageSize,
        total,
      });
      const creativesBatch = creatives.slice(start, end);

      // If the user clicks on a page in the middle of the list
      // we need to fill the previous pages with empty creatives
      // Those get replaced by the actual creatives when we fetch them
      if (creatives.length < start) {
        for (let i = creatives.length; i < start; i++) {
          creatives.push(
            getEmptyCreative({
              idx: i,
            }),
          );
        }
      }

      // Replace empty creative with actual creative
      if (
        creativesBatch[input.batchIdx] != null &&
        isEmptyCreative(creativesBatch[input.batchIdx])
      ) {
        creatives.splice(start + input.batchIdx, 1, input.creative);
        return;
      }

      // Add creative to creatives
      creatives.splice(start + input.batchIdx, 0, input.creative);
    },

    getCockpitFetchedCreatives(input: {
      columnId: number;
      groupId: number;
      cellIdx: number;
      pageNumber: number;
      pageSize: number;
    }) {
      const columnIdx = this.columns.findIndex(
        (column) => column.info.id === input.columnId,
      );
      const groupIdx = this.columns[columnIdx].cells[
        input.cellIdx
      ].groups.findIndex((group) => group.info.id === input.groupId);
      const total =
        this.columns[columnIdx].cells[input.cellIdx].groups[groupIdx].total;
      const creatives =
        this.columns[columnIdx].cells[input.cellIdx].groups[groupIdx].creatives;
      return this.getFetchedCreatives({
        creatives,
        pageNumber: input.pageNumber,
        pageSize: input.pageSize,
        total,
      });
    },

    getViewFetchedCreatives(input: {
      viewId: number;
      columnId: number;
      groupId: number;
      cellIdx: number;
      pageNumber: number;
      pageSize: number;
    }) {
      const reportIdx = this.reports.findIndex((report) =>
        report.views.some((view) => view.info.id === input.viewId),
      );
      const viewIdx = this.reports[reportIdx].views.findIndex(
        (view) => view.info.id === input.viewId,
      );
      const columnIdx = this.reports[reportIdx].views[
        viewIdx
      ].columns.findIndex((column) => column.info.id === input.columnId);
      const groupIdx = this.reports[reportIdx].views[viewIdx].columns[
        columnIdx
      ].cells[input.cellIdx].groups.findIndex(
        (group) => group.info.id === input.groupId,
      );
      const total =
        this.reports[reportIdx].views[viewIdx].columns[columnIdx].cells[
          input.cellIdx
        ].groups[groupIdx].total;
      const creatives =
        this.reports[reportIdx].views[viewIdx].columns[columnIdx].cells[
          input.cellIdx
        ].groups[groupIdx].creatives;
      return this.getFetchedCreatives({
        creatives,
        pageNumber: input.pageNumber,
        pageSize: input.pageSize,
        total,
      });
    },

    getFetchedCreatives(input: {
      creatives: Creative[];
      pageNumber: number;
      pageSize: number;
      total: number;
    }) {
      const { start, end } = getBatchStartEnd({
        pageNumber: input.pageNumber,
        pageSize: input.pageSize,
        total: input.total,
      });
      const hasFetchedCreatives =
        input.creatives.length >= end &&
        input.creatives.every((c) => c.info.metrics.length > 0);
      if (!hasFetchedCreatives) {
        return {
          hasFetchedGroups: false,
          groupsBatch: [],
        };
      }
      const creativesBatch = input.creatives.slice(start, end);
      const { allCreativesNotEmpty } = useCreatives();
      return {
        hasFetchedCreatives: allCreativesNotEmpty(creativesBatch),
        creativesBatch,
      };
    },

    addCustomMetricRule(rule: CustomMetricRule) {
      this.customMetricRules.push(rule);
    },

    async updateCustomMetricRuleOfView(dto: {
      newRule: CustomMetricRule;
      oldRule: CustomMetricRule;
      viewId: number;
    }) {
      const { newRule, oldRule, viewId } = dto;
      const index = this.customMetricRules.findIndex(
        (r) => r.customMetricRuleId === newRule.customMetricRuleId,
      );
      if (index < 0) {
        return;
      }
      this.customMetricRules.splice(index, 1, { ...newRule });
      const isRenamed = newRule.metricName !== oldRule.metricName;
      if (!isRenamed) {
        return;
      }
      const view = this.reports
        .flatMap((report) => report.views)
        .find((view) => view.info.id === viewId);
      if (view == null) {
        return;
      }
      const { updateQuery } = useQuery();
      if (view.info.filter != null) {
        const { renameFiltersWithCustomMetric } = useFilter();
        view.info.filter = renameFiltersWithCustomMetric({
          oldMetricName: oldRule.metricName,
          newMetricName: newRule.metricName,
          filter: view.info.filter,
        });
        await updateQuery({
          filters: JSON.stringify(view.info.filter),
        });
      }
      for (const column of view.columns) {
        column.info.secondaryMetrics = column.info.secondaryMetrics.map((m) =>
          m === oldRule.metricName ? newRule.metricName : m,
        );
        await updateQuery({
          secondaryMetrics: JSON.stringify(column.info.secondaryMetrics),
        });
        if (column.info.primaryMetric === oldRule.metricName) {
          column.info.primaryMetric = newRule.metricName;
          await updateQuery({
            primaryMetric: column.info.primaryMetric,
          });
        }
      }
    },

    async updateCustomMetricRuleOfColumn(dto: {
      newRule: CustomMetricRule;
      oldRule: CustomMetricRule;
      columnId: number;
    }) {
      const { newRule, oldRule, columnId } = dto;
      const index = this.customMetricRules.findIndex(
        (r) => r.customMetricRuleId === newRule.customMetricRuleId,
      );
      if (index < 0) {
        return;
      }
      this.customMetricRules.splice(index, 1, { ...newRule });
      const isRenamed = newRule.metricName !== oldRule.metricName;
      if (!isRenamed) {
        return;
      }
      const column = this.columns.find((column) => column.info.id === columnId);
      if (column == null) {
        return;
      }
      const { updateQuery } = useQuery();
      const gridIdx = column.info.secondaryMetrics.findIndex(
        (m) => m === oldRule.metricName,
      );
      if (gridIdx >= 0) {
        column.info.secondaryMetrics.splice(gridIdx, 1, newRule.metricName);
        await updateQuery({
          secondaryMetrics: JSON.stringify(column.info.secondaryMetrics),
        });
      }
      if (column.info.primaryMetric === oldRule.metricName) {
        column.info.primaryMetric = newRule.metricName;
        await updateQuery({
          primaryMetric: column.info.primaryMetric,
        });
      }
    },

    async deleteCustomMetricRuleOfView(ruleId: number, viewId: number) {
      const index = this.customMetricRules.findIndex(
        (r) => r.customMetricRuleId === ruleId,
      );
      if (index < 0) {
        return;
      }
      const rule = { ...this.customMetricRules[index] };
      this.customMetricRules.splice(index, 1);
      const view = this.reports
        .flatMap((report) => report.views)
        .find((view) => view.info.id === viewId);
      if (view == null) {
        return;
      }
      const { updateQuery } = useQuery();
      if (view.info.filter != null) {
        const { removeFiltersWithCustomMetric } = useFilter();
        view.info.filter = removeFiltersWithCustomMetric({
          metricName: rule.metricName,
          filter: view.info.filter,
        });
        await updateQuery({
          filters: JSON.stringify(view.info.filter),
        });
      }
      for (const column of view.columns) {
        const gridIdx = column.info.secondaryMetrics.findIndex(
          (m) => m === rule.metricName,
        );
        if (gridIdx >= 0) {
          column.info.secondaryMetrics.splice(gridIdx, 1);
          await updateQuery({
            secondaryMetrics: JSON.stringify(column.info.secondaryMetrics),
          });
        }
        if (column.info.primaryMetric === rule.metricName) {
          const { getProviderDefaultMetric } = useMetrics();
          column.info.primaryMetric = getProviderDefaultMetric(
            column.info.provider,
          );
          await updateQuery({
            primaryMetric: column.info.primaryMetric,
          });
        }
      }
    },

    async deleteCustomMetricRuleOfColumn(ruleId: number, columnId: number) {
      const index = this.customMetricRules.findIndex(
        (r) => r.customMetricRuleId === ruleId,
      );
      if (index < 0) {
        return;
      }
      const rule = { ...this.customMetricRules[index] };
      this.customMetricRules.splice(index, 1);
      const column = this.columns.find((column) => column.info.id === columnId);
      if (column == null) {
        return;
      }
      const { updateQuery } = useQuery();
      const gridIdx = column.info.secondaryMetrics.findIndex(
        (m) => m === rule.metricName,
      );
      if (gridIdx >= 0) {
        column.info.secondaryMetrics.splice(gridIdx, 1);
        await updateQuery({
          secondaryMetrics: JSON.stringify(column.info.secondaryMetrics),
        });
      }
      if (column.info.primaryMetric === rule.metricName) {
        const { getProviderDefaultMetric } = useMetrics();
        column.info.primaryMetric = getProviderDefaultMetric(
          column.info.provider,
        );
        await updateQuery({
          primaryMetric: column.info.primaryMetric,
        });
      }
    },

    assignTagsToAd(dto: { adId: number; tags: AdGroupAdTag[] }) {
      this.reports = this.reports.map((report) => {
        report.views = report.views.map((view) => {
          view.columns = view.columns.map((column) => {
            column.cells = column.cells.map((cell) => {
              cell.groups = cell.groups.map((group) => {
                group.creatives = group.creatives.map((creative) => {
                  if (creative.info.id === dto.adId) {
                    creative.info.tags = [...dto.tags];
                  }
                  return creative;
                });
                return group;
              });
              return cell;
            });
            return column;
          });
          return view;
        });
        return report;
      });
    },

    removeTagFromAd(dto: { adId: number; tagId: number }) {
      this.reports = this.reports.map((report) => {
        report.views = report.views.map((view) => {
          view.columns = view.columns.map((column) => {
            column.cells = column.cells.map((cell) => {
              cell.groups = cell.groups.map((group) => {
                group.creatives = group.creatives.map((creative) => {
                  const idx = creative.info.tags.findIndex(
                    (tag) => tag.id === dto.tagId,
                  );
                  if (creative.info.id === dto.adId && idx !== -1) {
                    creative.info.tags.splice(idx, 1);
                  }
                  return creative;
                });
                return group;
              });
              return cell;
            });
            return column;
          });
          return view;
        });
        return report;
      });
    },

    removeTagFromCreative(dto: { creativeId: string; tagId: number }) {
      this.reports = this.reports.map((report) => {
        report.views = report.views.map((view) => {
          view.columns = view.columns.map((column) => {
            column.cells = column.cells.map((cell) => {
              cell.groups = cell.groups.map((group) => {
                group.creatives = group.creatives.map((creative) => {
                  const idx = creative.info.tags.findIndex(
                    (tag) => tag.id === dto.tagId,
                  );
                  if (
                    creative.info.creativeId === dto.creativeId &&
                    idx !== -1
                  ) {
                    creative.info.tags.splice(idx, 1);
                  }
                  return creative;
                });
                return group;
              });
              return cell;
            });
            return column;
          });
          return view;
        });
        return report;
      });
    },

    updateTagAcrossAds(dto: { tag: AdGroupAdTag }) {
      this.reports = this.reports.map((report) => {
        report.views = report.views.map((view) => {
          view.columns = view.columns.map((column) => {
            column.cells = column.cells.map((cell) => {
              cell.groups = cell.groups.map((group) => {
                group.creatives = group.creatives.map((creative) => {
                  creative.info.tags = creative.info.tags.map((tag) =>
                    tag.id === dto.tag.id ? dto.tag : tag,
                  );
                  return creative;
                });
                return group;
              });
              return cell;
            });
            return column;
          });
          return view;
        });
        return report;
      });
    },

    removeTagAcrossAds(dto: { tagId: number }) {
      this.reports = this.reports.map((report) => {
        report.views = report.views.map((view) => {
          view.columns = view.columns.map((column) => {
            column.cells = column.cells.map((cell) => {
              cell.groups = cell.groups.map((group) => {
                group.creatives = group.creatives.map((creative) => {
                  creative.info.tags = creative.info.tags.filter(
                    (tag) => tag.id !== dto.tagId,
                  );
                  return creative;
                });
                return group;
              });
              return cell;
            });
            return column;
          });
          return view;
        });
        return report;
      });
    },
  },
  getters: {
    getColumnsOfClient: (state) => (clientId: number) => {
      return state.columns
        .sort((a, b) => sortAscending(a.info.order, b.info.order))
        .filter((column) => column.info.clientId === clientId);
    },

    getColumnsOfView: (state) => (viewId: number) => {
      const viewMaybe = state.reports
        .flatMap((report) => report.views)
        .find((view) => view.info.id === viewId);
      if (viewMaybe == null) return [];
      return viewMaybe.columns.sort((a, b) =>
        sortAscending(a.info.order, b.info.order),
      );
    },

    getColumnById: (state) => (columnId: number) => {
      const cockpitColumn =
        state.columns.find((column) => column.info.id === columnId) ?? null;
      if (cockpitColumn != null) return cockpitColumn;
      const viewColumn =
        state.reports
          .map((report) => report.views)
          .flat()
          .map((view) => view.columns)
          .flat()
          .find((column) => column.info.id === columnId) ?? null;
      return viewColumn;
    },

    getGroupById:
      (state) =>
      (dto: {
        groupId: number;
        cellIdx: number | undefined | null;
        columnId: number | undefined | null;
      }) => {
        const { groupId, cellIdx, columnId } = dto;
        if (cellIdx == null || columnId == null) return null;
        const cockpitGroup =
          state.columns
            .filter((column) => column.info.id === columnId)
            .map((column) => column.cells[cellIdx].groups)
            .flat()
            .find((group) => group.info.id === groupId) ?? null;
        if (cockpitGroup != null) return cockpitGroup;
        const viewGroup =
          state.reports
            .map((report) => report.views)
            .flat()
            .map((view) => view.columns)
            .flat()
            .filter((column) => column.info.id === columnId)
            .map((column) => column.cells[cellIdx].groups)
            .flat()
            .find((group) => group.info.id === groupId) ?? null;
        return viewGroup;
      },

    getReportsOfClient: (state) => (clientId: number) => {
      return state.reports.filter((report) => report.clientId === clientId);
    },

    getReportById: (state) => (reportId: number) => {
      return state.reports.find((report) => report.id === reportId) ?? null;
    },

    getReportByUuid: (state) => (reportUuid: string) => {
      return state.reports.find((report) => report.uuid === reportUuid) ?? null;
    },
  },
});

// Enable hot reloading when in development
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useTestingLogStore, import.meta.hot));
}
